.nav-link {
  font-size: 14px;
  font-family: "jioType-light", Arial, Helvetica, sans-serif;
  color: #fff !important;
  line-height: 30px;
  text-transform: uppercase;
  margin-right: 12px;
  &:hover {
    color: #fff;
    &:after {
      content: "";
      border-bottom: 1px solid #fff;
      width: 30px;
      display: block;
    }
  }
}
.navbar {
  padding: 0.5rem 0;
  z-index: 1;
}

.navbar-nav {
  .active {
    .nav-link {
      font-family: "jioType-bold", Arial, Helvetica, sans-serif;
      &:after {
        content: "";
        border-bottom: 1px solid #fff;
        width: 30px;
        display: block;
      }
    }
  }
}

.searchcont {
  input {
    border-radius: 20px;
    text-align: right;
    background: none;
    padding: 0 15px;
  }
  .form-control::-webkit-input-placeholder {
    color: white;
  } /* WebKit, Blink, Edge */
  .form-control:-moz-placeholder {
    color: white;
  } /* Mozilla Firefox 4 to 18 */
  .form-control::-moz-placeholder {
    color: white;
  } /* Mozilla Firefox 19+ */
  .form-control:-ms-input-placeholder {
    color: white;
  } /* Internet Explorer 10-11 */
  .form-control::-ms-input-placeholder {
    color: white;
  } /* Microsoft Edge */
  .form-control {
    color: #fff;
  }
  .form-control:focus {
    color: #fff;
    background: none !important;
    border-color: #fff;
    outline: 0;
    box-shadow: none;
  }
}

.user {
  .dropdown-item {
    padding: 0.25rem 1.5rem;
    font-family: "jioType-light", Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #393939;
    text-align: right;
    white-space: nowrap;
    background-color: transparent;
    border-bottom: 1px solid #ccc;
    &:hover {
      color: #1a1866;
    }
  }
  button {
    background: none;
    border: 0;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: 0;
    }
  }
}

.no-border {
  border: 0 !important;
}

/*
@include media-breakpoint-down(lg) {
.navbar-toggler {
  border: 0px solid transparent;
  text-align: right;
  width: 100%;
}
.navbar-expand-xl .navbar-nav {
  flex-direction: row;
}
.navbar-nav, .searchcont{
  float: left;
}
.navbar-toggler:focus{
  outline: none;
}
.navbar-collapse{
  margin-top: 20px;
}
}
*/

.header-outer{
  width: 100%;
  float: left;  padding: 7px 0; 
  height: 15vh
}
.outer{
  width: 94%; margin: 0 auto;
}
.leftsidebar-bingotask {
  float: left;
  width: 20%;
  .logo-desk {
      padding: 16px 0;
      width: 100%;
      text-align: left;
      display: block;
  }
  .logo-mb {
      display: none;
  }

}
.rightsidebar-bingotask {
  float: right; width: 80%;
}

@include media-breakpoint-down(lg) {
  /* .navbar-expand-xl .navbar-nav {
    flex-direction: column;
  }
  .navbar-nav, .searchcont{
    float: none;
  }*/

  .nav-link {
    &:hover {
      &:after {
        display: none;
      }
    }
  }
  .navbar-nav .active .nav-link:after {
    display: none;
  }
  .navbar-toggler {
    border: 0px solid transparent;
    text-align: right;
    width: 100%;
    padding: 10px 40px 0 0;
  }

  .navbar-toggler:focus {
    outline: none;
  }
  .navbar-collapse {
    float: right;
    text-align: right;
    background: rgba(6, 2, 36, 0.9);
    margin-top: 12px;
    padding: 7px 27px;
  }
  .navbar {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }
  .searchcont {
    display: block;
    padding-bottom: 14px;
  }
  .notification {
    margin-top: 5px;
    margin-bottom: 12px;
  }
  .user {
    img {
      width: 45px;
    }
  }
  .nav-link {
    line-height: 22px;
  }

}
@include media-breakpoint-down(md) {
  .navbar-toggler {
    padding: 5px 15px 0 0;
  }
  .navbar {
    top: 5px !important;
    right: 0;
    position: fixed;
    padding: 0;
    z-index: 20;
  }
  .navbar-collapse {
    margin-top: 10px;
    padding: 7px 5px;
  }
  .leftsidebar-bingotask {
    width: 50%;
    .logo-desk {
        display: none;
    }
    .logo-mb {
      padding: 12px 0;
        width: 100%;
        text-align: left;
        display: block;
        float: left;
    }
  
  }
}
