footer{
    padding: 65px 0 0 0;
    background: #F4F4F4;
    .support-links{
        margin-top: 40px;
    .txt1{
        color: #555555;
        font-size: 28px;
        font-family: "jioType-bold", Arial, Helvetica, sans-serif;
    }
    .txt2{
        color: #555555;
        font-size: 16px;
        font-family: "jioType-medium", Arial, Helvetica, sans-serif;
        .fa-envelope, .fa-phone{
            margin-right: 10px;
        }
        a{
            color: #555555;
        }
    }
 }
 .footerlinks{
     width: 100%;
     .links{
         width: auto;
         float: left;
         padding-right: 20px;
         ul{
             margin: 0;
             padding: 0;font-family: "jioType-medium", Arial, Helvetica, sans-serif;
             li{
                 list-style: none;
                 color: #959595;
                 font-size: 15px;
                 a{
                    color: #959595;
                    text-decoration: none;
                 }
                 &:first-child{
                     color: #555555;
                     font-size: 17px;
                     a{
                        color: #555555;
                        text-decoration: none;
                     }
                 }
             }
         }
     }
 }
}

.footerfix{
    width: 100%;
    background: #555555;
    color: #fff;
    font-size: 10px;
    padding: 7px 0;
    z-index: 1;
    margin-top: 100px;
    .footer-left a {        
        color: #fff;
     }
}

.fixed-footer{
    position: fixed;
    bottom: 0;
}