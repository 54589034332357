@import 'bootstrap/bootstrap';
@import 'plugins/font-awesome/fontawesome';
@import 'plugins/font-awesome/brands';
@import 'plugins/font-awesome/regular';
@import 'plugins/font-awesome/solid';
//@import 'plugins/jquery.mCustomScrollbar.min';
//@import 'plugins/fancybox';
@import 'plugins/owl.carousel';
@import 'plugins/prefixer';
@import 'plugins/animate';

@import 'layout/default';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/home';